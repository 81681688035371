import * as React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import constants from '../../utils/data/constants.data';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px !important',
    backgroundImage: 'url("' + constants.BG_04 + '")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FormModal({
    onClose,
    openModal = false,
    children,
}) {
    const handleClose = () => onClose();

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {children}
            </Box>
        </Modal>
    );
}