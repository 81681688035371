import * as React from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Icon, Paper, Typography } from '@mui/material';
import { Parallax } from "react-parallax";
import homeData from '../../utils/data/home.data';


const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        pt: 2,
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE',
        textAlign: 'center'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '3rem',
        color: 'white',
        textAlign: 'center'
    },
    typo3: {
        // marginTop: '1.2rem',
        fontSize: 30,
        color: 'white',
    },
    typo4: {
        marginBottom: '1.2rem',
        fontSize: 15,
        paddingRight: '1rem',
        color: 'white',
    },
    effect1: {
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
        }
    },
    techIconsCard: {
        padding: '0.8rem',
        margin: '1rem',
        height: '13rem',
        background: '#770EFE',
        '& .MuiCardMedia-img': {
            width: '50%',
            margin: 'auto',
        },
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
            opacity: 0.75,
        },
        alignItems: 'center',
    },
    keyFocusIcon: {
        width: 60,
        height: 60,
        marginTop: '1rem',
        WebkitFilter: "invert(1)",
        filter: "invert(1)",
    },
}

export default function KeyFocus({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{ key: '', value: '', icon: '' }],
}, id = "key-focus" }) {

    return (
        <>
            <Parallax bgImage={homeData?.constants?.BG_01} strength={1000}>
                <Paper id={id} sx={{ pl: 3, py: 9, margin: 'auto', flexGrow: 1, backgroundColor: 'transparent', textAlign: 'justify' }}>
                    <Container fixed>
                        <Grid container>
                            <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                                <Grid item md={12} >
                                    <Typography sx={styles.typo1}>
                                        {content?.title}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo2}>
                                        {content?.heading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo4}>
                                        {content?.subHeading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container spacing={2}>
                                        {
                                            content?.content?.map((_, index) => {
                                                return (
                                                    <Grid item md={3} sm={6} xs={6} key={"key-focus-grid-" + index} data-aos="zoom-in">
                                                        <Card sx={styles.techIconsCard}>
                                                            {/* <CardMedia
                                                                component="img"
                                                                height={150}
                                                                image={"images/key-focus-icons/" + _?.key}
                                                                alt="GES2022 Key Focus Area"
                                                            /> */}
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h6" component="div" sx={{ textAlign: 'center', }}>
                                                                    <img style={styles.keyFocusIcon} src={"images/key-focus-icons/" + _?.icon?.toString()} alt="GWMS2022 Key Focus Area" />
                                                                </Typography>
                                                                <Typography gutterBottom variant="h6" component="div" sx={{ color: '#CCCCCC', textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>
                                                                    {_?.value?.toString()}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Parallax>
        </>
    );
}