import React from 'react';
import './App.css';
import Routing from './router';

function App() {
  return (
    <Routing />
  );
}

export default App;
