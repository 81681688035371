import { Box } from '@mui/material';
import * as React from 'react';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ResponsiveAppBar from '../components/footer';
import Header from '../components/header';
import KeyFocus from '../components/key-focus';
import MediaPartners from '../components/media-partners';
import NewsArticles from '../components/news-articles';
import Overview from '../components/overview';
import Register from '../components/registration';
import Speakers from '../components/speakers';
import Sponsors from '../components/sponsors';
import WhatsInStore from '../components/whats-in-store';
import WhoAttends from '../components/who-attends';
import AOS from "aos";
import "aos/dist/aos.css";
import data from '../utils/data/home.data';
import GalleryComponent from '../components/gallery';


export default function Gallery() {
    const content: any = data?.data;

    React.useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        AOS.refresh();
    }, []);

    return (
        <>
            {/* <ResponsiveAppBar /> */}
            <Header fixedHeader={true} />
            <GalleryComponent id="gallery" />
            <Footer id="footer" content={content?.footer} contact={content?.footerContact} />
        </>
    );
}