import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, IconButton, Link, Paper, Typography, useMediaQuery } from '@mui/material';
import { Parallax } from "react-parallax";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const styles = {
    typo1: {
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: 'white'
    },
    typo2: {
        fontSize: 24,
        paddingTop: '0.5rem',
        fontWeight: 700,
        marginBottom: '2rem',
        color: 'white',
    },
    typo3: {
        marginBottom: '1.2rem',
        fontSize: 15,
        paddingRight: '1rem',
        color: '#e0e0e0'
    },
    typo4: {
        marginBottom: '1.2rem',
        fontSize: 14,
        paddingRight: '1rem',
        textAlign: 'justify',
        color: '#e0e0e0'
    },
    typo5: {
        fontWeight: 'normal',
        pl: '1rem',
        paddingBottom: '0.5rem',
        overflow: 'hidden',
        '&:hover': {
            fontWeight: 'bold',
        },
        color: '#e0e0e0'
    },
}

export default function Footer({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [''],
}, contact = {
    title: '',
    heading: '', subHeading: '', content: [{
        key: '',
        value: '',
    }]
}, id = "footer" }) {
    const matches = useMediaQuery("(min-width:600px)");

    return (
        <>
            <Paper id={id} sx={{ pl: 3, py: 9, margin: 'auto', flexGrow: 1, backgroundColor: '#2F0565', textAlign: 'justify' }}>
                <Container fixed>
                    <Grid container>
                        <Grid item md={12} >
                            <Typography sx={styles.typo1}>
                                {content?.title}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container spacing={0}>
                                <Grid item md={4} xs={12}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <Typography sx={styles.typo2}>
                                                {content?.heading}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={12}>
                                            {
                                                content?.content?.map((_, index) => (<Typography sx={styles.typo4} key={"footer-p-" + index} component="p" >{_.toString()}</Typography>))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <Typography sx={styles.typo2}>
                                                {contact?.heading}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container>
                                                {
                                                    contact?.content?.map((_, index) => (
                                                        <Grid item md={12} key={"footer-p-contact-" + index} >
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: (matches ? 18 : 12), pl: '1rem', overflow: 'hidden', color: '#e0e0e0' }} component="p" >
                                                                {_?.key === 'phone' ?
                                                                    (<CallIcon sx={{ mb: '-0.5rem' }} />) :
                                                                    (<MailIcon sx={{ mb: '-0.5rem', mt: '1rem' }} />)}
                                                                {" "}
                                                                {_?.value}
                                                            </Typography>
                                                        </Grid>
                                                    ))
                                                }
                                                <Grid item md={12} key={"footer-p-contact-social"} p={'1rem 0.5rem'}>
                                                    <IconButton sx={{ color: '#0077b5' }} href={'https://www.linkedin.com/showcase/the-global-workforce-management-summit-2022/'} target={'_blank'} >
                                                        <LinkedInIcon fontSize='large' />
                                                    </IconButton>
                                                    <IconButton sx={{ color: '#00acee' }} href={'https://twitter.com/Intercsa'} target={'_blank'} >
                                                        <TwitterIcon fontSize='large' />
                                                    </IconButton>
                                                    <IconButton sx={{ color: '#8a3ab9' }} href={'https://www.instagram.com/intercsa/'} target={'_blank'} >
                                                        <InstagramIcon fontSize='large' />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <Typography sx={styles.typo2}>
                                                Useful Links
                                            </Typography>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container>
                                                <Grid item md={12} >
                                                    <Typography sx={{ ...(styles.typo5), fontSize: (matches ? 18 : 12) }} component="p" >
                                                        <Link underline="none" color="inherit" href="#about">About</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Typography sx={{ ...(styles.typo5), fontSize: (matches ? 18 : 12) }} component="p" >
                                                        <Link underline="none" color="inherit" href="#speakers">Speakers</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Typography sx={{ ...(styles.typo5), fontSize: (matches ? 18 : 12) }} component="p" >
                                                        <Link underline="none" color="inherit" href="#sponsors">Sponsors</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Typography sx={{ ...(styles.typo5), fontSize: (matches ? 18 : 12) }} component="p" >
                                                        <Link underline="none" color="inherit" href="#register">Contact Us</Link>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} pt={3}>
                            <Divider />
                            <Typography sx={{ ...(styles.typo4), pt: 3, mb: 0, textAlign: 'center' }} component="p" >
                                © 2021 <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>Global Workforce Management Summit</span> All Rights Reserved.
                            </Typography>
                            <Typography sx={{ ...(styles.typo4), pt: 0, mb: 0, textAlign: 'center' }} component="p" >
                                Conceptualised and Organised by <span style={{ fontWeight: 'bold' }}>
                                    <Link href='https://intercsa.com/' target={'_blank'} color="inherit" underline="hover">
                                        International Center for Strategic Alliances
                                    </Link>
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    );
}