import * as React from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography } from '@mui/material';
import { Parallax } from "react-parallax";
import homeData from '../../utils/data/home.data';

const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        pt: 2,
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE',
        textAlign: 'center'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '1rem',
        color: 'white',
        textAlign: 'center'
    },
    typo3: {
        // marginTop: '1.2rem',
        fontSize: 30,
        color: 'white'
    },
    typo4: {
        marginBottom: '3rem',
        fontSize: 18,
        paddingRight: '1rem',
        color: 'white',
        textAlign: 'center'
    },
    effect1: {
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
        }
    },
    techIconsCard: {
        background: 'transparent',
        '& .MuiCardMedia-img': {
            width: '50%',
            margin: 'auto',
        },
    },
}

export default function WhoAttends({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{ key: '', value: '' }],
}, id = "who-attends" }) {
    return (
        <>
            <Parallax bgImage={homeData?.constants?.BG_01} strength={1000}>
                <Paper id={id} sx={{ pl: 3, py: 9, margin: 'auto', flexGrow: 1, backgroundColor: 'transparent' }}>
                    <Container fixed>
                        <Grid container>
                            <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                                <Grid item md={12} >
                                    <Typography sx={styles.typo1}>
                                        {content?.title}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo2}>
                                        {content?.heading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo4}>
                                        {content?.subHeading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    {/* <Grid container>
                                        {
                                            content?.content?.map((_, index) => (
                                                <Grid container sx={styles.effect1} key={"who-attends-grid-" + index}>
                                                    <Grid item md={2}>
                                                        <img
                                                            alt="GES2022 Logo"
                                                            src={"images/tech-icons/" + _?.key}
                                                            style={{ width: 'auto', height: 90, margin: '1.9em 1.8em 0.9em 0' }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={10} sx={{ margin: 'auto' }}>
                                                        <Typography sx={styles.typo3} key={"overview-p-" + index} component="h1" >{_?.value?.toString()}</Typography>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid> */}
                                    <Grid container spacing={2}>
                                        {
                                            content?.content?.map((_, index) => {
                                                return (
                                                    <Grid item md={4} sm={6} xs={6} key={"overview-grid-" + index} sx={styles.effect1}>
                                                        <Card sx={{ display: 'flex' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                                    <Typography component="div" variant="h5" sx={{ fontWeight: 'bold' }}>
                                                                        {_?.value?.toString()}
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="text.secondary" component="div">

                                                                    </Typography>
                                                                </CardContent>
                                                            </Box>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: 131 }}
                                                                image={"logo.png"}
                                                                alt="GES2022 Who Attends"
                                                            />
                                                        </Card>
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid>
                                    {/* <Grid container spacing={2}>
                                        {
                                            content?.content?.map((_, index) => {
                                                return (
                                                    <Grid item md={2.4} sm={6} xs={6} key={"overview-grid-" + index}>
                                                        <Card sx={styles.techIconsCard}>
                                                            <CardMedia
                                                                component="img"
                                                                height={100}
                                                                image={"images/tech-icons/" + _?.key}
                                                                alt="GES2022 Who Attends"
                                                            />
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h6" component="div" sx={{ color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 13 }}>
                                                                    {_?.value?.toString()}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Parallax>
        </>
    );
}