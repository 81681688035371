import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#770EFE',
    },
    secondary: {
      main: '#23D1DC',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
  typography: {
    h2: {
      fontFamily: 'Raleway',
    },
    fontFamily: 'Raleway',
  },
});

// theme = responsiveFontSizes(theme);

export default theme;
