import countries from "./countries";

const constants = {
    // Base Constants
    API_URL: 'https://globalemergetechsummit.com/api/',
    COUNTRIES: countries,

    // Images
    LOGO: 'images/gwms-logo.png',
    BANNER_BG: 'images/gwms-banner-bg.jpg',
    OVERVIEW_IMAGE: 'images/about-3-img.png',
    BG_01: 'images/gwms-bg-image-07.jpg',
    BG_02: 'images/gwms-bg-image-02.jpg',
    BG_03: 'images/gwms-bg-image-03.jpg',
    BG_04: 'images/gwms-bg-image-04.jpg',
    BG_05: 'images/gwms-bg-image-05.jpg',
    BG_06: 'images/awms-bg-01.png',
    BG_07: 'images/awms-bg-02.png',
    BG_08: 'images/awms-bg-03.png',

    iBG_01: 'images/awms2021/img-01.png',
    iBG_02: 'images/awms2021/img-02.png',
    iBG_03: 'images/awms2021/img-03.png',
    iBG_04: 'images/awms2021/img-04.png',

    // Texts
    SPONSORSHIP_MAIL_URL: 'mailto:info@globalemergetechsummit.com?cc=info@intercsa.com&subject=Interested%20in%20Sponsoring%20at%20GES2022,%20Dubai',
    BROCHURE_MAIL_URL: 'mailto:info@globalemergetechsummit.com?cc=info@intercsa.com&subject=Request%20for%20Brochure%20-%20GES2022,%20Dubai',
};

export default constants;