import * as React from 'react';
import { Alert, AlertColor, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography, Link } from '@mui/material';
import { Parallax, Background } from "react-parallax";
import homeData from '../../utils/data/home.data';
import SpeakerListRequestFormModal from '../../common/speaker-request-form';
import { borderRadius } from '@mui/system';

const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE',
        textAlign: 'center'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '3rem',
        textAlign: 'center',
        color: 'white'
    },
    typo3: {
        marginBottom: '1.2rem',
        fontSize: 15,
        paddingRight: '1rem',
        color: 'white'
    },
    typo4: {
        width: '100%',
        textAlign: 'center',
        color: 'white'
    },
    carousel: {
        // minWidth: '30%',
    },
    buttonDiv: {
        paddingTop: '3rem',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    button: {
        padding: '1rem 3rem',
        border: '4px solid #770EFE',
        fontSize: 18,
        textAlign: 'center',
    },
    alert: {
        width: 'fit-content',
        position: 'fixed',
        left: '1em',
        bottom: '1em',
        zIndex: '9',
        fontSize: 18,
    },
    alertHidden: {
        opacity: 0,
        transition: 'all 1000ms linear 2s',
    },
    alertShown: {
        opacity: 1,
        transition: 'all 1000ms linear 2s',
    },
    gridImage: {
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
        },
    },
};

export default function AtAGlance({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{
        name: '',
        image: '',
        title: '',
        company: ''
    }]
}, id = "at-a-glance" }) {
    const [showBrochureModal, setShowBrochureModal] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState("success");
    const [alertMessage, setAlertMessage] = React.useState("");

    const showPopup = () => setShowBrochureModal(true);

    const disableAlert = () => {
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    return (
        <>
            <Parallax bgImage={homeData?.constants?.BG_08} strength={300}>
                <Paper id={id} sx={{ pl: 3, py: 10, margin: 'auto', flexGrow: 1, backgroundColor: 'transparent' }}>
                    <Container fixed>
                        <Grid container spacing={0}>
                            <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                                <Grid item md={12} >
                                    <Typography sx={styles.typo1}>
                                        {content?.title}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo2}>
                                        {content?.heading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo3}>
                                        {content?.subHeading}
                                    </Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={6} sx={styles.gridImage}>
                                        <img src={homeData?.constants?.iBG_01} style={{ width: '100%', borderRadius: '10%' }} />
                                    </Grid>
                                    <Grid item md={6} sx={styles.gridImage}>
                                        <img src={homeData?.constants?.iBG_02} style={{ width: '100%', borderRadius: '10%' }} />
                                    </Grid>
                                    <Grid item md={6} sx={styles.gridImage}>
                                        <img src={homeData?.constants?.iBG_03} style={{ width: '100%', borderRadius: '10%' }} />
                                    </Grid>
                                    <Grid item md={6} sx={styles.gridImage}>
                                        <img src={homeData?.constants?.iBG_04} style={{ width: '100%', borderRadius: '10%' }} />
                                    </Grid>
                                </Grid>
                                <Grid item md={12} pt={3} sx={{ margin: 'auto' }}>
                                    <Typography sx={styles.typo4} variant={'h5'}>
                                        For the complete post show report, click <Link href="#" onClick={showPopup}>here</Link>
                                    </Typography>
                                </Grid>
                                {/* <Grid item md={12} p={1} sx={styles.buttonDiv}>
                                <Button sx={styles.button} variant='outlined'>
                                    Download Brochure
                                </Button>
                            </Grid> */}
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Parallax>
        </>
    );
}