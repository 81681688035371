import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { Parallax, Background } from "react-parallax";
import homeData from '../../utils/data/home.data';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import makeUrl, { TCalendarEvent } from 'add-event-to-calendar';

const styles = {
    illustrationDiv: {
        // borderLeft: '9px solid #770EFE',
        // borderBottom: '9px solid #770EFE',
        // borderTop: '9px solid #770EFE',
        // borderRadius: '0px !important',
        // color: 'white',
        padding: '3em 3em 0em 3em',
    },
    typo1: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        paddingTop: '3em',
    },
    typo2: {
        lineHeight: 1,
        fontWeight: '900',
        paddingRight: '1em',
        // paddingTop: '3em',
        paddingBottom: '0.5em',
    },
    typo3: {
        overflow: 'visible',
        paddingRight: '1em',
        // width: '100%',
    },
    typo4: {
        fontWeight: 'bold',
        // fontStyle: 'italic',
        textTransform: 'uppercase',
        overflow: 'visible',
        color: '#770EFE',
        // opacity: 0.9,
    },
    linkedInBtn: {
        margin: 'auto 2rem',
        // float: 'right',
        color: '#0E76A8',
        background: 'white',
        '&:hover': {
            color: 'white',
        }
    },
    calendarBtn: {
        margin: 'auto',
        // float: 'right',
        color: '#0E76A8',
        background: 'white',
        '&:hover': {
            color: 'white',
        }
    },
    calendarMenuItem: {
        // backgroundColor: '#770EFE'
    },
}

const getWindowDimensions = (hasWindow) => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
        width,
        height,
    };
}

const VideoComponent = ((props: any) => {
    const width: number = props.width;
    return (
        <video width={width || '100%'} height="auto" muted autoPlay controls={false} preload='metadata'>
            <source src="videos/gwms-banner-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
});

export default function Banner() {
    const matches = useMediaQuery("(min-width:600px)");
    const hasWindow = typeof window !== 'undefined';

    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions(hasWindow));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (hasWindow) {
            window.addEventListener('resize', () => {
                setWindowDimensions(getWindowDimensions(hasWindow));
            });
            return () => window.removeEventListener('resize', () => {
                setWindowDimensions(getWindowDimensions(hasWindow));
            });
        }
    }, [hasWindow]);

    // Create a Calendar Event
    const getCalendarEvent = (): TCalendarEvent => ({
        name: "Global Workforce Management Summit 2022",
        location: "Virtual",
        details: "GWMS2022 - Global Workforce Management Summit 2022 | Virtual | ICSA - International Center for Strategic Alliances",
        startsAt: "2022-06-22T12:00:00.000+04:00",
        endsAt: "2022-06-23T18:00:00.000+04:00",
    });

    const eventUrls = makeUrl(getCalendarEvent());

    const onDownloadEvent = (type: string) => {
        if (type == 'download') {
            window.open(eventUrls?.ics, '_self');
        } else if (type == 'google') {
            window.open(eventUrls?.google, '_blank');
        } else if (type == 'outlook') {
            window.open(eventUrls?.outlook, '_blank');
        } else {
            window.open(eventUrls?.ics, '_self');
        }
    }

    return (
        <>

            <Parallax strength={300}>
                {/* @ts-ignore: Unnecessary Overload */}
                <Background className="custom-bg">
                    <VideoComponent width={windowDimensions?.width} />
                </Background>
                {/* <Background className="custom-bg">
                    <img src={homeData?.constants?.BANNER_BG} />
                    <video width={windowDimensions?.width || '100%'} height="auto" muted autoPlay controls={false} preload='metadata'>
                        <source src="videos/gwms-banner-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Background> */}

                <div style={{ height: (!matches ? 600 : 800) }}>
                    <Grid container spacing={0}>
                        {
                            !matches && (
                                <Grid item md={6} sx={{ mt: 20 }}>
                                    <Card sx={{ background: 'transparent', border: 0, overflow: 'visible' }} variant="outlined">
                                        <CardContent style={styles.illustrationDiv}>
                                            <Typography sx={styles.typo1} fontSize={{
                                                md: 18,
                                                sm: 15,
                                            }} gutterBottom>
                                                22nd - 23rd June 2022, VIRTUAL
                                            </Typography>
                                            <Typography variant="h2" component="div" sx={styles.typo2} fontSize={{
                                                lg: 81,
                                                md: 60,
                                                sm: 50,
                                                xs: 33,
                                            }}>
                                                GLOBAL WORKFORCE MANAGEMENT SUMMIT 2022
                                            </Typography>
                                            <Typography sx={{ ...styles.typo4, width: (matches ? '162%' : '100%') }} fontSize={{
                                                md: 18,
                                                sm: 15,
                                                xs: 12,
                                            }} gutterBottom>
                                                "Fostering a Human and Humanized Future of Work"
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                        <Grid item md={(!matches ? 6 : 12)} sx={{ mt: (matches ? 20 : 6), width: '100%', bottom: (!matches ? '3em' : '0.5em'), position: (!matches ? 'relative' : 'absolute') }}>
                            <Grid item md={12} sm={12} xs={12}>
                                <Grid container spacing={0} margin={'auto'} justifyContent="center" alignItems="center" direction="column">
                                    <Grid item md={12} sm={12} m={1}>

                                    </Grid>
                                    <Grid item md={12} sm={12} m={1}>
                                        <Button variant="contained"
                                            href='https://www.linkedin.com/showcase/the-global-workforce-management-summit-2022/'
                                            target={'_blank'}
                                            size="large"
                                            sx={styles.linkedInBtn}
                                            startIcon={<LinkedInIcon />}
                                        >
                                            Know More
                                        </Button>
                                        <Button variant="contained"
                                            aria-controls={open ? 'calendar-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            startIcon={<EventAvailableIcon />}
                                            size="large"
                                            sx={styles.calendarBtn}>
                                            Add To Calendar
                                        </Button>
                                        <Menu
                                            id="calendar-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => { onDownloadEvent('download') }} sx={styles.calendarMenuItem}>
                                                <ListItemIcon>
                                                    <EventAvailableIcon />
                                                </ListItemIcon>
                                                <ListItemText>Download Event </ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => { onDownloadEvent('google') }} sx={styles.calendarMenuItem}>
                                                <ListItemIcon>
                                                    <GoogleIcon />
                                                </ListItemIcon>
                                                <ListItemText>Save to Google Calendar</ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => { onDownloadEvent('outlook') }} sx={styles.calendarMenuItem}>
                                                <ListItemIcon>
                                                    <EmailIcon />
                                                </ListItemIcon>
                                                <ListItemText>Save to Outlook</ListItemText>
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                    <Grid item md={12} sm={12} m={1}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

            </Parallax>
        </>
    );
}