import * as React from 'react';
import { useParams } from 'react-router-dom';

export default function Speaker() {
    let { id } = useParams<{ id: string }>();

    console.log("Speaker", id);

    return (
        <>Speaker</>
    );
}