import { Box } from '@mui/material';
import * as React from 'react';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ResponsiveAppBar from '../components/footer';
import Header from '../components/header';
import KeyFocus from '../components/key-focus';
import MediaPartners from '../components/media-partners';
import NewsArticles from '../components/news-articles';
import Overview from '../components/overview';
import Register from '../components/registration';
import Speakers from '../components/speakers';
import Sponsors from '../components/sponsors';
import WhatsInStore from '../components/whats-in-store';
import WhoAttends from '../components/who-attends';
import AOS from "aos";
import "aos/dist/aos.css";
import data from '../utils/data/home.data';
import AtAGlance from '../components/at-a-glance';


export default function Home() {
    const content = data?.data;

    React.useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        AOS.refresh();
    }, []);

    return (
        <>
            <Header />
            <Banner />
            <Overview id="about" content={content?.overview} />

            {/* <WhatsInStore id="in-store" content={content?.inStore} /> */}
            <KeyFocus id="key-focus" content={content?.keyFocus} />
            <AtAGlance id="at-a-glance" content={content?.atAGlance} />
            <Speakers id="speakers" content={content?.speakers} pastContent={content?.pastSpeakers} />
            <WhoAttends id="who-attends" content={content?.whoWillAttend} />
            <Register id="register" content={content?.register} />
            <MediaPartners id="media-partners" content={content?.mediaPartners} />
            {/* <Sponsors id="sponsors" content={content?.sponsors} />
            <NewsArticles id="news" content={content?.newsArticles} /> */}
            <Footer id="footer" content={content?.footer} contact={content?.footerContact} />
        </>
    );
}