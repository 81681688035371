import axios from "axios";
import CONSTANTS from './../data/constants.data';

const API_URL = CONSTANTS.API_URL;

export default {
    registerUser: async (
        fullName: any = null,
        email: any = null,
        contact: any = null,
        designation: any = null,
        org: any = null,
        country: any = null,
    ) => {
        try {
            var bodyFormData = new FormData();
            var data = {
                fullName, email, contact, designation, org, country
            };

            for (const [key, value] of Object.entries(data)) {
                if (value !== null) {
                    bodyFormData.append(key, value);
                }
            }

            return await axios.post(API_URL + "register_api.php", bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" },
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    requestBrochure: async (
        fullName: any = null,
        email: any = null,
        contact: any = null,
        designation: any = null,
        org: any = null,
        country: any = null,
    ) => {
        try {
            var bodyFormData = new FormData();
            var data = {
                fullName, email, contact, designation, org, country
            };

            for (const [key, value] of Object.entries(data)) {
                if (value !== null) {
                    bodyFormData.append(key, value);
                }
            }

            return await axios.post(API_URL + "request_api.php", bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" },
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    requestSponsorship: async (
        fullName: any = null,
        email: any = null,
        contact: any = null,
        designation: any = null,
        org: any = null,
        country: any = null,
    ) => {
        try {
            var bodyFormData = new FormData();
            var data = {
                fullName, email, contact, designation, org, country
            };

            for (const [key, value] of Object.entries(data)) {
                if (value !== null) {
                    bodyFormData.append(key, value);
                }
            }

            return await axios.post(API_URL + "sponsorship_api.php", bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" },
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}