import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent, Container, Grid, Paper, Typography } from '@mui/material';
import { Parallax, Background } from "react-parallax";
import homeData from '../../utils/data/home.data';

const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        pt: 2,
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE',
        textAlign: 'center',
        marginBottom: '3rem',
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        // marginBottom: '1rem',
        color: 'white',
        textAlign: 'center'
    },
    typo3: {
        marginBottom: '1.2rem',
        fontSize: 16,
        fontWeight: 700,
        color: 'white',
        textIndent: '50px',
    },
}

export default function Overview({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [''],
}, id = "about" }) {
    return (
        <>
            <Parallax bgImage={homeData?.constants?.BG_05} strength={1000}>
                <Paper id={id} sx={{ pl: 3, py: 9, margin: 'auto', flexGrow: 1, backgroundColor: 'transparent', textAlign: 'justify' }}>
                    <Container fixed>
                        <Grid container>
                            <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo2}>
                                        {content?.heading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} >
                                    <Typography sx={styles.typo1}>
                                        {content?.title}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo3} component="div">
                                        {
                                            content?.content?.map((_, index) => (<Typography sx={styles.typo3} key={"overview-p-" + index} component="p" >{_.toString()}</Typography>))
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <Grid item md={4}>
                                <Paper elevation={3} sx={{ width: '100%', height: '100%', backgroundImage: 'url(' + (homeData?.constants?.OVERVIEW_IMAGE) + ')' }}></Paper>
                            </Grid> */}
                        </Grid>
                    </Container>
                </Paper>
            </Parallax>
        </>
    );
}