import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Gallery from './pages/gallery';
import Home from './pages/home';
import Speaker from './pages/speaker';
import Speakers from './pages/speakers';
import Sponsors from './pages/sponsors';

class Routing extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    {/* Default Route */}
                    <Route path="/" exact component={Home} />

                    {/* Base Routes */}
                    <Route path="/gallery" exact component={Gallery} />
                    <Route path="/sponsors" exact component={Sponsors} />
                    <Route path="/speakers" exact component={Speakers} />
                    <Route path="/speaker/:id" exact component={Speaker} />

                    {/* Wildcard Route */}
                    <Route path="*" render={() => (<Redirect to="/" />)} />
                </Switch >
            </Router>
        );
    }
}

export default Routing;