import * as React from 'react';
import { Alert, AlertColor, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import homeData from '../../utils/data/home.data';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import Gallery from "react-photo-gallery";

// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '3rem',
    },
    typo3: {
        marginBottom: '1.2rem',
        fontSize: 15,
        paddingRight: '1rem',
    },
    carousel: {
        // minWidth: '30%',
    },
    effect1: {
        margin: 'auto',
        cursor: 'pointer',
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
        }
    },
    buttonDiv: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    button: {
        padding: '1rem 3rem',
        border: '4px solid #770EFE',
        fontSize: 18,
        textAlign: 'center',
    },
    alert: {
        width: 'fit-content',
        position: 'fixed',
        left: '1em',
        bottom: '1em',
        zIndex: '9',
        fontSize: 18,
    },
    alertHidden: {
        opacity: 0,
        transition: 'all 1000ms linear 2s',
    },
    alertShown: {
        opacity: 1,
        transition: 'all 1000ms linear 2s',
    },
};

export default function GalleryComponent({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{
        key: '',
        title: '',
        value: '',
    }]
}, id = "speakers" }) {
    const [showSponsorshipModal, setShowSponsorshipModal] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState("success");
    const [alertMessage, setAlertMessage] = React.useState("");
    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

    const showPopup = () => setShowSponsorshipModal(true);

    const disableAlert = () => {
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    const openLightbox = React.useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const classes = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            // marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }));
    const photos = [
        {
            src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
            width: 1,
            height: 1
        },
        {
            src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/PpOHJezOalU/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
            width: 4,
            height: 3
        }
    ];


    return (
        <>
            <div>
                <Gallery photos={photos} onClick={openLightbox} />
                {/* <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.src,
                                    caption: ''
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway> */}
            </div>
        </>
    );
}