import * as React from 'react';
import { Alert, AlertColor, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Parallax, Background } from "react-parallax";
import homeData from '../../utils/data/home.data';
import appService from '../../utils/services/app.service';
import countries from '../../utils/data/countries';

const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        pt: 2,
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE',
        textAlign: 'center'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '3rem',
        color: 'white',
        textAlign: 'center'
    },
    typo3: {
        // marginTop: '1.2rem',
        fontSize: 30,
        color: 'white',
    },
    typo4: {
        marginBottom: '1.2rem',
        fontSize: 21,
        // fontWeight: 'bold',
        paddingRight: '1rem',
        color: 'white',
    },
    typo5: {
        margin: '1rem',
        fontSize: 15,
        color: 'lightgray',
        fontStyle: 'italic',
    },
    effect1: {
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
        }
    },
    techIconsCard: {
        background: 'transparent',
    },
    formInputs: {
        color: 'white',
        fontSize: 18,
        width: '100%',
    },
    alert: {
        width: 'fit-content',
        position: 'fixed',
        left: '1em',
        bottom: '1em',
        zIndex: '9',
        fontSize: 18,
    },
    alertHidden: {
        opacity: 0,
        transition: 'all 1000ms linear 2s',
    },
    alertShown: {
        opacity: 1,
        transition: 'all 1000ms linear 2s',
    },
}

export default function Register({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [''],
}, id = "register" }) {
    // Component Hooks
    const [disableForm, setDisableForm] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState("success");
    const [alertMessage, setAlertMessage] = React.useState("");

    const [formValues, setFormValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        designation: '',
        org: '',
        country: '',
    });

    // Component Methods
    const onSubmitForm = async () => {
        setDisableForm(true);
        // Service Call
        try {
            const { firstName, lastName, email, contact, designation, org, country } = formValues;
            if (firstName.trim() === '' || lastName.trim() === '' || email.trim() === '' || contact.trim() === '' || designation.trim() === '' || org.trim() === '' || country.trim() === '') {
                // Validation Error
                setAlertMessage("Please fill all values in the form.");
                setAlertType("error");
                setShowAlert(true);
            } else {
                const res = await appService.registerUser(firstName + " " + lastName, email, contact, designation, org, country);
                const resData = res?.data || null;
                if (resData === null || resData?.error === true) {
                    // Server Error
                    setAlertMessage(resData?.message || "Please refresh the page and try again.");
                    setAlertType("error");
                    setShowAlert(true);
                } else {
                    setAlertMessage("Successfully submitted. Our liaison officer will be in touch with you shortly.")
                    setAlertType("success");
                    setShowAlert(true);
                    setFormValues({
                        firstName: '',
                        lastName: '',
                        email: '',
                        contact: '',
                        designation: '',
                        org: '',
                        country: '',
                    });
                }
            }
            setDisableForm(false);
            disableAlert();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    const disableAlert = () => {
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    const onUpdateValues = (key: string, value: string) => {
        setFormValues(_ => {
            let _prevState = _ as any;
            _prevState[key] = value;
            return { ..._prevState };
        })
    }

    return (
        <>
            {
                showAlert && (
                    <Alert sx={styles.alert} onClose={() => setShowAlert(false)} severity={alertType as AlertColor}>{alertMessage}</Alert>
                )
            }
            <Parallax bgImage={homeData?.constants?.BG_07} strength={300}>
                <Paper id={id} sx={{ pl: 3, py: 9, margin: 'auto', flexGrow: 1, backgroundColor: 'transparent', textAlign: 'justify' }}>
                    <Container fixed>
                        <Grid container>
                            <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                                <Grid item md={12} >
                                    <Typography sx={styles.typo1}>
                                        {content?.title}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo2}>
                                        {content?.heading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo4}>
                                        {content?.subHeading}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                    color: 'white',
                                                    background: '#000000',
                                                    padding: '1rem 2rem 2rem 2rem',
                                                    borderRadius: '25px'
                                                }}
                                                noValidate
                                            >
                                                <Grid container>
                                                    <Grid item md={6}>
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: styles.formInputs,
                                                            }}
                                                            sx={{ input: { color: 'white' } }}
                                                            required
                                                            id="firstName"
                                                            type={'text'}
                                                            label="First Name"
                                                            variant="standard"
                                                            onChange={(e) => onUpdateValues("firstName", e.target.value)}
                                                            value={formValues['firstName']}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: styles.formInputs,
                                                            }}
                                                            sx={{ input: { color: 'white' } }}
                                                            required
                                                            id="lastName"
                                                            type={'text'}
                                                            label="Last Name"
                                                            variant="standard"
                                                            onChange={(e) => onUpdateValues("lastName", e.target.value)}
                                                            value={formValues['lastName']}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: styles.formInputs,
                                                            }}
                                                            sx={{ input: { color: 'white' } }}
                                                            required
                                                            id="designation"
                                                            type={'text'}
                                                            label="Designation"
                                                            variant="standard"
                                                            onChange={(e) => onUpdateValues("designation", e.target.value)}
                                                            value={formValues['designation']}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: styles.formInputs,
                                                            }}
                                                            sx={{ input: { color: 'white' } }}
                                                            required
                                                            id="org"
                                                            type={'text'}
                                                            label="Organization"
                                                            variant="standard"
                                                            onChange={(e) => onUpdateValues("org", e.target.value)}
                                                            value={formValues['org']}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: styles.formInputs,
                                                                itemType: 'email'
                                                            }}
                                                            sx={{ input: { color: 'white' } }}
                                                            required
                                                            id="email"
                                                            label="Email Address"
                                                            type={'email'}
                                                            variant="standard"
                                                            onChange={(e) => onUpdateValues("email", e.target.value)}
                                                            value={formValues['email']}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <FormControl variant="standard" sx={{ m: 1, width: '90%' }}>
                                                            <InputLabel style={styles.formInputs} id="country">Country Code *</InputLabel>
                                                            <Select
                                                                labelId=""
                                                                id="country"
                                                                value={formValues['country']}
                                                                onChange={(e) => onUpdateValues("country", e.target.value)}
                                                                label="Country Code"
                                                                sx={{ color: 'white' }}
                                                                autoComplete="off"
                                                            >
                                                                <MenuItem value="">
                                                                    <em> - Select Country Code - </em>
                                                                </MenuItem>
                                                                {
                                                                    countries?.map((_, index) => (
                                                                        <MenuItem key={"country-select-" + index} value={_?.label}>
                                                                            <img
                                                                                loading="lazy"
                                                                                width="20"
                                                                                src={`https://flagcdn.com/w20/${_?.code?.toLowerCase()}.png`}
                                                                                srcSet={`https://flagcdn.com/w40/${_?.code?.toLowerCase()}.png 2x`}
                                                                                alt={`Flag of ${_?.label}`}
                                                                                style={{ marginRight: '0.5rem' }}
                                                                            />
                                                                            {" "} {_?.label} ({_?.code}) +{_?.phone}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: styles.formInputs,
                                                            }}
                                                            sx={{ input: { color: 'white' } }}
                                                            required
                                                            id="contact"
                                                            label="Contact Number"
                                                            type={'text'}
                                                            variant="standard"
                                                            onChange={(e) => onUpdateValues("contact", e.target.value)}
                                                            value={formValues['contact']}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}></Grid>
                                                    <Grid item md={12} sx={{ margin: 'auto' }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{ my: 1, mx: 2, px: 6, color: 'white', display: 'block', fontSize: 18 }}
                                                            onClick={() => { onSubmitForm(); }}
                                                            {...(disableForm ? { disabled: true } : {})}
                                                        >
                                                            Register
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography sx={styles.typo5}>
                                        *By submitting this form, you authorize the event organizers to contact you for registration.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Parallax>
        </>
    );
}