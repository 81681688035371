import * as React from 'react';
import { Alert, AlertColor, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography, Link } from '@mui/material';
import { Parallax, Background } from "react-parallax";
import homeData from '../../utils/data/home.data';
import SpeakerListRequestFormModal from '../../common/speaker-request-form';
// import { makeStyles } from '@material-ui/styles';


const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE',
        textAlign: 'center'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '3rem',
        textAlign: 'center',
        marginTop: '0rem',
    },
    typo3: {
        marginBottom: '1.2rem',
        fontSize: 15,
        paddingRight: '1rem',
    },
    typo4: {
        width: '100%',
        textAlign: 'center',
    },
    carousel: {
        // minWidth: '30%',
    },
    buttonDiv: {
        paddingTop: '3rem',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    button: {
        padding: '1rem 3rem',
        border: '4px solid #770EFE',
        fontSize: 18,
        textAlign: 'center',
    },
    alert: {
        width: 'fit-content',
        position: 'fixed',
        left: '1em',
        bottom: '1em',
        zIndex: '9',
        fontSize: 18,
    },
    alertHidden: {
        opacity: 0,
        transition: 'all 1000ms linear 2s',
    },
    alertShown: {
        opacity: 1,
        transition: 'all 1000ms linear 2s',
    },
    downloadBtn: {
        marginTop: '1rem',
        padding: '0.5rem 3rem',
        border: '1px solid #770EFE',
        fontSize: 15,
        textAlign: 'center',
    },
    speakerCard: {
        background: 'transparent',
        position: 'relative',
    },
    speakerBox: {
        height: '8rem',
        width: '100%',
        background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
        zIndex: '99',
        bottom: 0,
        left: 0,
        position: 'absolute',
        display: 'inline-block',
        alignSelf: 'flex-end',
        color: 'white',
        paddingLeft: '1rem',
    },
    speakerImage: {
        borderRadius: '0%',
        border: 'none',
        maxHeight: '20rem',
        width: '100%',
        transition: 'transform 1s',
        '&:hover': {
            transform: 'scale(1.2)',
        },
    },
};

export default function Speakers({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{
        name: '',
        image: '',
        title: '',
        company: ''
    }]
}, pastContent = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{
        name: '',
        image: '',
        title: '',
        company: ''
    }]
}, id = "speakers" }) {
    const [showBrochureModal, setShowBrochureModal] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState("success");
    const [alertMessage, setAlertMessage] = React.useState("");

    const showPopup = () => setShowBrochureModal(true);

    const disableAlert = () => {
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    return (
        <>
            {
                showBrochureModal && (
                    <SpeakerListRequestFormModal onClose={() => setShowBrochureModal(false)} openModal={showBrochureModal} disableAlert={disableAlert} setShowAlert={setShowAlert} setAlertType={setAlertType} setAlertMessage={setAlertMessage} />
                )
            }
            {
                showAlert && (
                    <Alert sx={styles.alert} onClose={() => setShowAlert(false)} severity={alertType as AlertColor}>{alertMessage}</Alert>
                )
            }
            <Paper id={id} sx={{ pl: 3, py: 10, margin: 'auto', flexGrow: 1, backgroundColor: 'white' }}>
                <Container fixed>
                    <Grid container spacing={0}>
                        <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                            <Grid item md={12} >
                                <Typography sx={styles.typo1}>
                                    {content?.title}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography sx={styles.typo2}>
                                    {content?.heading}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography sx={styles.typo3}>
                                    {content?.subHeading}
                                </Typography>
                            </Grid>
                            <Grid container>
                                {
                                    content?.content?.map((_, index) => {
                                        while (index < 30) {
                                            return (
                                                <Grid item md={4} sm={12} xs={12} p={1} key={"speaker-grid-" + index}>
                                                    <Card sx={styles.speakerCard} elevation={0}>
                                                        <Box>
                                                            <CardMedia
                                                                image={_?.image}
                                                                component="img"
                                                                width="100"
                                                                alt="GES2022 Speaker"
                                                                sx={styles.speakerImage}
                                                            />
                                                            <Box sx={styles.speakerBox}>
                                                                <Typography gutterBottom variant="h6" component="div">
                                                                    {_?.name}
                                                                </Typography>
                                                                <Typography variant="body2" color="white">
                                                                    {_?.title}
                                                                </Typography>
                                                                <Typography variant="body2" color="white" sx={{ fontWeight: 'bold' }}>
                                                                    {_?.company}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                            </Grid>
                            <Grid item md={12}>
                                <Typography sx={styles.typo2}>
                                    {pastContent?.heading}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography sx={styles.typo3}>
                                    {pastContent?.subHeading}
                                </Typography>
                            </Grid>
                            <Grid container>
                                {
                                    pastContent?.content?.map((_, index) => {
                                        while (index < 5) {
                                            return (
                                                <Grid item md={2.4} sm={12} xs={12} p={1} key={"speaker-grid-" + index}>
                                                    <Card sx={{
                                                        background: 'transparent',
                                                        borderRadius: '10% 10%',
                                                        border: 'none'
                                                    }}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                width="100"
                                                                image={_?.image}
                                                                alt="AWMS2021 Speaker"
                                                                style={{
                                                                    borderRadius: '20%',
                                                                    border: 'none',
                                                                    padding: '1rem'
                                                                }}
                                                            />
                                                            <CardContent >
                                                                <Typography gutterBottom variant="h6" component="div">
                                                                    {_?.name}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {_?.title}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                                    {_?.company}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                            </Grid>
                            <Grid item md={12} pt={3} sx={{ margin: 'auto' }}>
                                <Typography sx={styles.typo4} variant={'h5'}>
                                    For the complete post show report <br />
                                    {/* click <Link href="#" onClick={showPopup}>here</Link> */}
                                    <Button sx={styles.downloadBtn} variant='outlined' onClick={showPopup}>
                                        Click Here
                                    </Button>
                                </Typography>
                            </Grid>
                            {/* <Grid item md={12} p={1} sx={styles.buttonDiv}>
                                <Button sx={styles.button} variant='outlined'>
                                    Download Brochure
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    );
}