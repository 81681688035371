import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Link, makeStyles } from '@mui/material';
import { useHistory } from "react-router-dom";
import homeData from '../../utils/data/home.data';


const pages = [{
    name: 'Home',
    url: '#',
    type: 'internal',
    child: [],
}, {
    name: 'GWMS2022',
    url: "#about",
    type: 'internal',
    child: [{
        name: 'About GWMS2022',
        url: '#about',
        type: 'internal',
    }, {
        name: 'Event Focus',
        url: '#key-focus',
        type: 'internal',
    }, {
        name: 'Who Will Attend?',
        url: '#who-attends',
        type: 'internal',
    }, {
        name: 'Our Speakers',
        url: '#speakers',
        type: 'internal',
    }, {
        name: 'Our Partners',
        url: '#media-partners',
        type: 'internal',
    }],
}, {
    name: 'AWMS2021',
    url: '#at-a-glance',
    type: 'internal',
    // url: 'https://africaworkforcesummit.com/',
    // type: 'external',
    child: [],
    // child: [{
    //     name: 'AWMS2021 at a Glance',
    //     url: 'awms2021',
    //     type: 'internal',
    // }, {
    //     name: 'Past Summit Speakers',
    //     url: 'past-speakers',
    //     type: 'internal',
    // }, {
    //     name: 'Past Summit Sponsors',
    //     url: 'past-sponsors',
    //     type: 'internal',
    // }],
    // }, {
    //     name: 'Gallery',
    //     url: "/gallery",
    //     type: 'internal',
    //     child: [],
}, {
    name: 'Contact',
    url: "#register",
    type: 'internal',
    child: [],
}];

const styles = {
    appbar: {
        show: {
            background: 'black',
            color: 'white',
            height: '5rem'
        },
        hide: {
            background: 'transparent',
            color: 'white'
        },
    },
    menuBtn: {
        my: 2,
        mx: 1,
        color: 'inherit',
        display: 'block',
        fontSize: 16,
        flex: 1,
        fontWeight: 'bold',
        transition: 'transform 0.2s ease-in',
        '&:hover': {
            color: '#770EFE',
            transform: 'scale(1.1)',
            // backgroundColor: 'rgba(23, 2, 50, 0.3)',
            // border: '2px solid white'
        }
    }

};

const Header = ({
    fixedHeader = false,
}) => {
    const trigger = useScrollTrigger({
        target: window ? window : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // Hooks for Dropdown Menu
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const handleOpen1Menu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1Menu = () => {
        setAnchorEl1(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const handleOpen2Menu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2Menu = () => {
        setAnchorEl2(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" style={{ boxShadow: 'none' }} sx={trigger ? styles.appbar.show : styles.appbar.hide}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters variant='dense'>
                        {/* Non-mobile Menu */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <Link href="/" underline="none">
                                <img
                                    alt="GWMS2022 Logo"
                                    src={homeData?.constants?.LOGO}
                                    style={{ width: 'auto', height: (trigger ? 50 : 90), margin: (trigger ? '0.5em 1.8em 0em 0' : '1.9em 1.8em 0.9em 0') }}
                                />
                            </Link>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex' }, position: 'absolute', right: 0 }}>
                            {pages?.map((page, index) => (
                                <React.Fragment key={page.name}>
                                    {
                                        page?.child?.length === 0 ? (
                                            <Link key={page.name} href={page?.url} target={page?.type === "external" ? "_blank" : ""} underline="none" color="inherit" sx={{ mx: 1 }}>
                                                <Button
                                                    onClick={handleCloseNavMenu}
                                                    sx={styles.menuBtn}
                                                >
                                                    {page.name}
                                                </Button>
                                            </Link>
                                        ) : (
                                            <React.Fragment key={page.name}>
                                                <Link underline="none" color="inherit" sx={{ mx: 2 }}
                                                    aria-label="menu"
                                                    aria-controls={"menu-appbar-key-" + index}
                                                    aria-haspopup="true"
                                                    onMouseOver={page.name === "About" ? handleOpen1Menu : handleOpen2Menu}
                                                // onMouseLeave={page.name === "About" ? handleClose1Menu : handleClose2Menu}
                                                >
                                                    <Button
                                                        sx={styles.menuBtn}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                </Link>
                                                <Menu
                                                    id={"menu-appbar-key-" + index}
                                                    anchorEl={page.name === "About" ? anchorEl1 : anchorEl2}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    open={Boolean(page.name === "About" ? anchorEl1 : anchorEl2)}
                                                    onClose={page.name === "About" ? handleClose1Menu : handleClose2Menu}
                                                    MenuListProps={{ onMouseLeave: (page.name === "About" ? handleClose1Menu : handleClose2Menu) }}
                                                    sx={{
                                                        display: { xs: 'none', md: 'block' },
                                                    }}
                                                >
                                                    {page?.child?.map((child: any) => (
                                                        <Link key={child?.name} href={child?.url} target={child?.type === "external" ? "_blank" : ""} sx={{ textDecoration: 'none', color: 'inherit' }}>
                                                            <MenuItem onClick={page?.name === "About" ? handleClose1Menu : handleClose2Menu} sx={{ padding: '0.6rem 2rem' }}>
                                                                <Typography textAlign="center" >

                                                                    {child?.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        </Link>
                                                    ))}
                                                </Menu>
                                            </React.Fragment>
                                        )
                                    }
                                </React.Fragment>
                            ))}
                            {/* <Button
                                variant="contained"
                                sx={{ my: 1, mx: 2, color: 'white', display: 'block', fontSize: 16, pt: 2 }}
                                href='#register'
                            >
                                Register Your Interest
                            </Button> */}
                        </Box>

                        {/* Mobile Menu */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            <Link href="/" underline="none">
                                <img
                                    alt="GES2022 Logo"
                                    src={homeData?.constants?.LOGO}
                                    style={{ width: 'auto', height: (trigger ? 50 : 60), margin: (trigger ? '1.2em 1.8em 0.9em 0' : '1.9em 1.8em 0.9em 0') }}
                                />
                            </Link>
                        </Typography>
                        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            // sx={{ marginRight: (trigger ? '-20rem' : '-20rem') }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {/* Mobile Screens */}
                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu} sx={{ padding: '0 2rem' }}>
                                        <Typography textAlign="center" >
                                            <Link href={page.url} sx={{ textDecoration: 'none' }}>
                                                {page.name}
                                            </Link>
                                        </Typography>
                                    </MenuItem>
                                ))}
                                {/* <MenuItem key={'menu-register'} onClick={handleCloseNavMenu} sx={{ padding: '0 2rem' }}>
                                    <Typography textAlign="center" >
                                        <Link href='#register' sx={{ textDecoration: 'none' }}>
                                            Register
                                        </Link>
                                    </Typography>
                                </MenuItem> */}
                                {/* <Button
                                    variant="contained"
                                    sx={{ my: 1, mx: 2, color: 'white', display: 'block', fontSize: 16, pt: 2 }}
                                    href='#register'
                                // onClick={() => { history.push('/#register'); }}
                                >
                                    Register Your Interest
                                </Button> */}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box >

    );
};
export default Header;