import * as React from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Link, ListSubheader, Paper, Typography, useMediaQuery } from '@mui/material';
import { Parallax } from "react-parallax";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import homeData from '../../utils/data/home.data';
import ItemsCarousel from "react-items-carousel";

const styles = {
    illustrationDiv: {
        borderLeft: '9px solid #770EFE',
        borderBottom: '9px solid #770EFE',
        borderTop: '9px solid #770EFE',
        borderRadius: '0px !important',
        color: 'white',
        padding: '3em 0 3em 3em',
    },
    typo1: {
        fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', color: '#770EFE'
    },
    typo2: {
        fontSize: 42,
        paddingTop: '1rem',
        fontWeight: 700,
        marginBottom: '3rem',
        color: 'white'
    },
    typo3: {
        marginBottom: '1.2rem',
        fontSize: 15,
        paddingRight: '1rem',
    },
    carousel: {
        // minWidth: '30%',
    },
    effect1: {
        margin: 'auto',
        cursor: 'pointer',
        transition: 'transform .5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
            animation: 'normal',
        },
    },
    buttonDiv: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    button: {
        padding: '1rem 3rem',
        border: '4px solid #770EFE',
        fontSize: 18,
        textAlign: 'center',
    },
    imageLink: {
        background: 'transparent',
        margin: 'auto',
    },
    imageBox: {
        width: 250,
        height: 150,
        margin: 'auto',
        display: 'flex',
        borderRadius: 10,
        backgroundColor: 'white',
        transition: 'linear 1s',
        '&:hover': {
            backgroundColor: 'white',
            opacity: [0.9, 0.8, 0.7],
            transform: 'scale(1.2)',
            borderRadius: 0,
        },
    },
    imageBoxMini: {
        width: 115,
        height: 120,
        margin: '0.5rem auto',
        display: 'flex',
        borderRadius: 50,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
            opacity: [0.9, 0.8, 0.7],
        },
    },
    imageBoxLitGoose: {
        background: '#EEECEC !important',
    },
    imageBoxCryptopolitan: {
        background: '#0E244B !important',
    },
};

export default function MediaPartners({ content = {
    title: '',
    heading: '',
    subHeading: '',
    content: [{
        key: '',
        title: '',
        url: '#',
        value: '',
    }]
}, id = "media-partners" }) {
    const matches = useMediaQuery("(min-width:600px)");
    const matchesTablet = useMediaQuery("(max-width:1200px)");

    const [active, setActive] = React.useState(0);

    React.useEffect(() => {
        setInterval(() => {
            setActive(_ps => {
                if (_ps == content?.content?.length - 1) {
                    return 0;
                } else {
                    return (_ps + 1);
                }
            })
        }, 5000);
    }, []);

    return (
        <Parallax bgImage={homeData?.constants?.BG_01} strength={300}>
            <Paper id={id} sx={{ pl: 3, pt: 10, pb: 6, margin: 'auto', flexGrow: 1, backgroundColor: 'transparent' }}>
                <Container fixed>
                    <Grid container spacing={0}>
                        <Grid item md={12} sx={{ paddingRight: '2rem' }}>
                            <Grid item md={12} >
                                <Typography sx={styles.typo1}>
                                    {content?.title}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography sx={styles.typo2}>
                                    {content?.heading}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography sx={styles.typo3}>
                                    {content?.subHeading}
                                </Typography>
                            </Grid>
                            <Grid container>
                                {
                                    matches ? (
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ItemsCarousel
                                                autoPlay
                                                infiniteLoop={true}
                                                gutter={12}
                                                timeout={1}
                                                activePosition={"center"}
                                                chevronWidth={60}
                                                disableSwipe={false}
                                                alwaysShowChevrons={true}
                                                numberOfCards={matchesTablet ? 3 : 4}
                                                slidesToScroll={1}
                                                outsideChevron={true}
                                                showSlither={false}
                                                firstAndLastGutter={false}
                                                activeItemIndex={active}
                                                requestToChangeActive={(value) => setActive(value)}
                                                rightChevron={">"}
                                                leftChevron={"<"}
                                            >
                                                {
                                                    content?.content?.map((_, index) => (
                                                        <Tooltip key={"media-partner-grid-" + index} TransitionComponent={Zoom} title={_?.title}>
                                                            <Link target="_blank" href={_?.url} sx={styles.imageLink}>
                                                                <Box
                                                                    sx={(_?.key == "cryptopolitan" ? { ...styles.imageBoxCryptopolitan, ...styles.imageBox } : (_?.key == "litgoose" ? { ...styles.imageBoxLitGoose, ...styles.imageBox } : { ...styles.imageBox, background: 'white' }))}
                                                                >
                                                                    <img
                                                                        src={"images/media-partners/" + _?.value}
                                                                        alt={_?.title}
                                                                        style={{ padding: '0.6rem', height: 'auto', width: '150px', margin: 'auto' }}
                                                                        loading="lazy"
                                                                    />
                                                                </Box>
                                                            </Link>
                                                        </Tooltip>
                                                    ))
                                                }
                                            </ItemsCarousel>
                                        </Grid>
                                    ) : (
                                        <React.Fragment>
                                            {
                                                content?.content?.map((_, index) => (
                                                    <Grid item md={2} sm={6} xs={6} key={"media-partner-grid-" + index} >
                                                        <Tooltip TransitionComponent={Zoom} title={_?.title} sx={{ background: 'white' }}>
                                                            <Link target="_blank" href={_?.url} sx={{ background: 'transparent', margin: 'auto' }}>
                                                                <Box
                                                                    sx={(_?.key == "cryptopolitan" ? { ...styles.imageBoxCryptopolitan, ...styles.imageBoxMini } : (_?.key == "litgoose" ? { ...styles.imageBoxLitGoose, ...styles.imageBoxMini } : { ...styles.imageBoxMini, background: 'white' }))}
                                                                // sx={{ ...styles.imageBoxMini, background: 'white' }}
                                                                >
                                                                    <img
                                                                        src={"images/media-partners/" + _?.value}
                                                                        alt={_?.title}
                                                                        style={{ padding: '1rem', height: 'auto', width: '100%', margin: 'auto' }}
                                                                        loading="lazy"
                                                                    />
                                                                </Box>
                                                            </Link>
                                                        </Tooltip>
                                                    </Grid>
                                                ))
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Parallax>
    );
}