import * as React from 'react';
import { Alert, AlertColor, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import FormModal from '../form-modal';
import appService from '../../utils/services/app.service';
import countries from '../../utils/data/countries';

const styles = {
    formInputs: {
        color: 'white',
        fontSize: 18,
        width: '100%',
    },
    formTextInput: {
        input: {
            color: 'white',
        },
        width: '100% !important',
    },
    modalTitle: {
        color: 'white',
        fontWeight: 'bold',
    }
}

export default function SpeakerListRequestFormModal({
    onClose,
    openModal = false,

    disableAlert,
    setShowAlert,
    setAlertType,
    setAlertMessage,
}) {
    // Component Hooks
    const [disableForm, setDisableForm] = React.useState(false);

    const [formValues, setFormValues] = React.useState({
        fullName: '',
        email: '',
        contact: '',
        designation: '',
        org: '',
        country: '',
    });

    // Component Methods
    const onSubmitForm = async () => {
        setDisableForm(true);
        // Service Call
        try {
            const { fullName, email, contact, designation, org, country } = formValues;
            if (fullName.trim() === '' || email.trim() === '' || contact.trim() === '' || designation.trim() === '' || org.trim() === '' || country.trim() === '') {
                // Validation Error
                setAlertMessage("Please fill all values in the form.");
                setAlertType("error");
                setShowAlert(true);
            } else {
                const res = await appService.requestBrochure(fullName, email, contact, designation, org, country);
                const resData = res?.data || null;
                if (resData === null || resData?.error === true) {
                    // Server Error
                    setAlertMessage(resData?.message || "Please refresh the page and try again.");
                    setAlertType("error");
                    setShowAlert(true);
                } else {
                    setAlertMessage("Successfully requested. Our liaison officer will be in touch with you shortly.")
                    setAlertType("success");
                    setShowAlert(true);
                    setFormValues({
                        fullName: '',
                        email: '',
                        contact: '',
                        designation: '',
                        org: '',
                        country: '',
                    });
                }
            }
            setDisableForm(false);
            disableAlert();
            onClose();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    const onUpdateValues = (key: string, value: string) => {
        setFormValues(_ => {
            let _prevState = _ as any;
            _prevState[key] = value;
            return { ..._prevState };
        })
    }

    return (
        <FormModal onClose={onClose} openModal={openModal} children={
            <>
                <Typography id="sponsorship-modal-title" variant="h5" component="h2" sx={styles.modalTitle}>
                    Request for the AWMS2021 post show report
                </Typography>
                <Grid item md={12} xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            color: 'white',
                        }}
                        noValidate
                    >
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        style: styles.formInputs,
                                    }}
                                    sx={styles.formTextInput}
                                    required
                                    id="fullName"
                                    type={'text'}
                                    label="Full Name"
                                    variant="standard"
                                    onChange={(e) => onUpdateValues("fullName", e.target.value)}
                                    value={formValues['fullName']}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        style: styles.formInputs,
                                        itemType: 'email'
                                    }}
                                    sx={styles.formTextInput}
                                    required
                                    id="email"
                                    label="Email Address"
                                    type={'email'}
                                    variant="standard"
                                    onChange={(e) => onUpdateValues("email", e.target.value)}
                                    value={formValues['email']}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        style: styles.formInputs,
                                    }}
                                    sx={styles.formTextInput}
                                    required
                                    id="contact"
                                    label="Contact Number"
                                    type={'text'}
                                    variant="standard"
                                    onChange={(e) => onUpdateValues("contact", e.target.value)}
                                    value={formValues['contact']}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel style={styles.formInputs} id="country">Country *</InputLabel>
                                    <Select
                                        labelId=""
                                        id="country"
                                        value={formValues['country']}
                                        onChange={(e) => onUpdateValues("country", e.target.value)}
                                        label="Age"
                                        sx={styles.formTextInput}
                                        autoComplete="off"
                                    >
                                        <MenuItem value="">
                                            <em> - Select - </em>
                                        </MenuItem>
                                        {
                                            countries?.map((_, index) => (
                                                <MenuItem key={"country-select-" + index} value={_.label}>{_?.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        style: styles.formInputs,
                                    }}
                                    sx={styles.formTextInput}
                                    required
                                    id="org"
                                    type={'text'}
                                    label="Organization"
                                    variant="standard"
                                    onChange={(e) => onUpdateValues("org", e.target.value)}
                                    value={formValues['org']}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        style: styles.formInputs,
                                    }}
                                    sx={styles.formTextInput}
                                    required
                                    id="designation"
                                    type={'text'}
                                    label="Designation"
                                    variant="standard"
                                    onChange={(e) => onUpdateValues("designation", e.target.value)}
                                    value={formValues['designation']}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{ px: 9, color: 'white', display: 'block', fontSize: 18, width: '90%', margin: '1rem auto' }}
                                    onClick={() => { onSubmitForm(); }}
                                    {...(disableForm ? { disabled: true } : {})}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </>
        }></FormModal>
    );
}